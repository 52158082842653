<template>
  <div
    :class="[fromCampaignPage ? 'campaign-image' : '']"
    :style="fromCampaignPage ? '' : 'height: 0'"
  >
    <img
      v-if="fromCampaignPage"
      id="bg-img"
      class="position-fixed d-none"
      :class="isTFW ? 'istfw' : ''"
      :src="getBackgroundImage"
      @error="
        $event.target.src = '/img/new_home_bg.png';
        $event.target.style = `width: ${getScreenSize.width}px; height: ${getScreenSize.height}px`;
      "
      alt="bg"
    />
    <div
      class="registration container-md col-lg-8 col-md-8 p-3"
      :class="[
        fromCampaignPage && `${getScreenSize.width}` > 1084
          ? 'card p-4 campaign-register'
          : 'pt-3',
      ]"
      :style="{
        height: displayPage === 1 && fromCampaignPage ? 'max-content' : 'auto ',
      }"
    >
      <div class="d-flex flex-row justify-content-between">
        <div class>
          <h3 class="pb-3 px-1 mb-0 main-title text-primary">
            {{
              displayPage == 2 || displayPage === 3
                ? "Registration"
                : displayPage === 5 || displayPage === 6
                ? ""
                : isCandidateSupplier
                ? "Add Candidate"
                :  displayPage === 4  ? "Quick Registration" : ""
            }}
          </h3>
        </div>
      </div>
      <div 
        v-if="displayPage == 1 && !isCandidateSupplier" 
        class="mx-auto custom-container">
        <h3 class="pb-3 px-1 mb-0 main-title text-primary">Registration</h3>
        <CCard class="mt-2 shadow-lg">
          <CCardBody>
            <CRow class="text-center">
              <label class="text-primary h5 font-weight-bold mt-2 ml-3">
                Please create your user email ID
              </label>
            </CRow>
            <CRow md="3" sm="12" class="text-left ml-1 mt-2">
              <label>Enter Your Email</label>
            </CRow>
            <CRow md="6" sm="12" :class="{ 'py-4': isMobile }">
              <div class="col-12">
                <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                  <TextInput
                    name="email"
                    :value="existingCandidate.email"
                    @input="handleExistingCandidateInput"
                    :error="errors[0]"
                    autocomplete="login-email"
                    ref="existingCandidate"
                    :disabled="isEmailDisabled"
                  />
                </ValidationProvider>
              </div>
            </CRow>
            <CRow md="3" sm="12" class="text-left ml-1 mt-2">
              <label>Confirm Your Email</label>
            </CRow>
            <CRow md="6" sm="12" :class="{ 'py-4': isMobile }">
                <div class="col-12">
                  <ValidationProvider rules="required|email|emailConfirmed:email" v-slot="{ errors }">
                    <TextInput
                      name="confirm_email"
                      :value="existingCandidate.confirm_email"
                      @input="handleExistingCandidateInput"
                      :error="errors[0]"
                      :disabled="isEmailDisabled"
                    />
                  </ValidationProvider>
                </div>
            </CRow>
            <CRow class="mt-3 text-center">
              <CCol md="12" sm="12">
                <CButton
                  class="btn-secondary"
                  @click="goToLogin">
                  Back
                </CButton>
                <CButton
                  name="registration-submit"
                  class="btn-primary ml-4"
                  type="submit"
                  ref="submitButton"
                  :disabled=isLoading
                  @click="checkCandidateExists()"
                >
                  <CSpinner v-if="isLoading" class="spinner-border-sm text-white" />
                  <span v-else>Next</span>
                </CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </div>
      <div v-else-if="displayPage === 3 && !isCandidateSupplier">
        <CCard class="mt-2">
          <CCardBody>
            <CRow class="mb-3">
              <CCol
                md="3"
                sm="12"
                :class="isMobile ? 'center-align mb-3' : 'center-align'"
              >
                <CRow name="email-label" style="height: 100%">
                  <label
                    class="text-primary col-lg-12 col-md-12 mt-2"
                    style="font-weight: bold"
                    >Email ID</label
                  >
                </CRow>
              </CCol>
              <CCol md="6" sm="12">
                <CRow name="email" style="height: 100%">
                  <div class="col-12">
                    <TextInput
                      name="email"
                      :value="existingCandidate.email"
                      :disabled="true"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="mt-3">
              <CCol md="2"></CCol>
              <CCol md="8" class="text-center">
                <p class="text-primary h6">You are already registered</p>
                <p v-if="getOrgName">
                  with <b>{{ getOrgName }}</b>
                </p>
                <p></p>
                <span class="text-primary h6">{{
                  getOrgVariant == Variant.campaign
                    ? "Please click below to new Sign Up"
                    : "Please click below to Sign In"
                }}</span>
              </CCol>
            </CRow>
            <CRow class="mt-3 center-align">
              <CCol md="5" class="center-align">
                <CButton class="btn-primary" @click="goToLogin">
                  {{
                    getOrgVariant == Variant.campaign ? "Sign Up" : "Sign In"
                  }}
                </CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </div>
      <div v-else-if="displayPage === 4">
        <QuickRegistration
          :customer="customer"
          :organisationID="organisationID"
          :locationID="locationID"
          :profilePhoto="profilePhoto"
          :isSubmitted="isSubmitted"
          :existingCandidate="existingCandidate"
          :isCandidateSupplier="isCandidateSupplier"
          :campaignData="candidate"
        />
      </div>
      <div
        v-else-if="displayPage === 5 && !isCandidateSupplier && !isTFW"
        class="d-flex justify-content-center align-items-center"
      >
        <CCard class="text-center" style="width: 600px">
          <CCardHeader class="bg-primary text-white h3"
            >Already Registered !</CCardHeader
          >
          <CCardTitle class="h3 text-left ml-4 pt-3">
            Welcome Back
            <span class="text-primary h3"
              >{{ registeredOrg[0].candidate_name | toTitleCase}}</span
            ></CCardTitle
          >
          <CCardBody class="px-5">
            <CRow name="email" class="mb-3" style="margin-left: -30px;">
              <CCol :sm="4" class="text-left">
                <label
                  class="text-primary text-left pt-2"
                  style="font-weight: bold"
                  >Email ID / UserName</label
                >
              </CCol>
              <CCol :sm="8" class="mt-2">
                <div>
                  <TextInput
                    name="email"
                    :value="existingCandidate.email"
                    :disabled="true"
                  />
                </div>
              </CCol>
            </CRow>
            <CRow class="mb-3">
              <CCardText class="text-justify fs-6">
                <p v-if="showProfileContent === 1">
                  <b class="text-primary">{{ getOrgNameOrAcronym }}</b> is a member of the TalentFind World
                  network – the global healthcare careers hub.
                  You’re already registered and have a TalentFind profile.
                  <br/>
                  <a
                    href="#"
                    class="font-weight-bold text-primary"
                    @click="getMasterCustomer(getRegisteredMasterCustomerID, 2)">
                  click here
                   </a> 
                   to use this profile to quickly register with <b class="text-primary">{{ getOrgNameOrAcronym }}</b>.
                </p>

                <p v-if="showProfileContent !== 1">
                  <b class="text-primary">{{ getOrgNameOrAcronym }}</b> 
                  is a member of the TalentFind World network – the global healthcare careers hub.
                  You are already registered with 
                  <b class="text-primary">{{ getRegisteredMasterCustomerName }}</b> 
                  and have a TalentFind profile.
                  <br/>
                  <a
                    href="#"
                    class="font-weight-bold"
                    @click="getMasterCustomer(getRegisteredMasterCustomerID, 2)">
                  click here
                  </a> 
                  to use this profile to quickly register with <b class="text-primary">{{ getOrgNameOrAcronym }}</b> 
                </p>
              </CCardText>
              <!-- <RadioButton
                name="customer_id"
                :value="userData.code"
                @change="handleChangeRadio"
                class="radio-field font-weight-bold text-primary text-left"
                :vertically="true"
                :options="registeredOrgOpt || []"
              /> -->
            </CRow>
            <CRow class="d-flex justify-content-center">
              <CButton class="btn-primary mr-3" @click="goToLogin()"
                >Home</CButton
              >
              <!-- <CButton
                class="btn-primary"
                @click="checkRadioField('customer_id')"
                >Next</CButton
              > -->
            </CRow>
          </CCardBody>
        </CCard>
      </div>
      <div
        v-else-if="displayPage === 6 && !isCandidateSupplier"
        class="d-flex justify-content-center align-items-center"
      >
        <CCard style="width: 500px">
          <CCardBody>
            <CCardTitle 
              class="text-primary text-center h2 mb-4" 
              style="font-weight: 500;">
              Welcome To {{ getOrgNameOrAcronym }}
            </CCardTitle>
            <h3 class="mb-2">
              Hello 
              <span class="text-primary" >
                {{
                  (isAlreadyRegistered
                    ? userInfo.candidate_name
                    : getRegisteredMasterCandidateName) | toTitleCase
                }}!
              </span>
            </h3>
            <CRow class="mb-3">
              <CCol md="12" class="font-weigth-bold mb-2">
                Please enter your Password
              </CCol>
              <CCol>
                <ValidationProvider rules="required" v-slot="{ errors }" ref="existingCandidatePassword">
                  <TextInput
                    name="password"
                    type="password"
                    :value="userData.password"
                    @input="handleInput"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </CCol>
            </CRow>
            <CRow>
              <CCol class="text-left">
                <CButton
                  @click="redirectToForgot"
                  color="link"
                  class="px-0"
                  >Forgot password?</CButton
                >
              </CCol>
            </CRow>
            <CRow class="d-flex justify-content-center">
              <CButton class="btn-secondary mr-3" @click="goBack()"
                >Back</CButton
              >
              <CButton
                v-if="!isAlreadyRegistered"
                class="btn-primary"
                :disabled="isLoading"
                @click="onExistingCandidate()"
                >Register</CButton
              >
              <CButton
                v-if="isAlreadyRegistered"
                class="btn-primary"
                :disabled="isLoading"
                @click="onLoginCandidate()"
                >Login</CButton
              >
            </CRow>
          </CCardBody>
        </CCard>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import m from "moment";
import { extend } from "vee-validate";
import {
  required,
  email,
  confirmed,
  regex,
  min,
  numeric,
  alpha_spaces,
} from "vee-validate/dist/rules";
import { mapGetters, mapActions } from "vuex";
import QuickRegistration from "@/containers/CandidateProfile/Registration/QuickRegistration";
import Modal from "@/components/reusable/Modal";

extend("required", { ...required, message: "This field is required" });
extend("password_length", {
  ...min,
  message: "Password be atleast 8 characters",
});
extend("password_strength", {
  ...regex,
  message: "Password must have capitals, numbers and special characters",
});
extend("confirmed", {
  ...confirmed,
  message: "This field should match password",
});
extend("email", { ...email, message: "Invalid email" });
extend("numeric", { ...numeric, message: "Only numbers are allowed" });
extend("phone_rule", {
  ...regex,
  message: "Only numbers are allowed(Maximum of 15 digits)",
});
extend("date_validate", (value) => {
  if (m(value).isValid()) {
    return true;
  }
  return "Invalid date! Enter a valid date";
});
extend("alpha_spaces", {
  ...alpha_spaces,
  message: "Only Alphabets are allowed",
});
extend("no_future_date", (value) => {
  let input_date = new Date(value);
  let today_date = new Date();
  if (input_date > today_date) {
    return "Given date should not be greater than today!";
  }
  return true;
});
extend("dob_check", {
  params: ["dob"],
  validate(value, { dob }) {
    let birth_date = new Date(dob);
    let input_date = new Date(value);
    if (!(input_date < birth_date)) {
      return true;
    }
  },
  message: "Given date should be between birthdate & today!",
});
extend("higher_qual_date_check", {
  params: ["first_qualified_date"],
  validate(value, { first_qualified_date }) {
    let first_qual_date = new Date(first_qualified_date);
    let input_date = new Date(value);
    if (!(input_date < first_qual_date)) {
      return true;
    }
  },
  message: "Given date should be greater than first qualification date!",
});

extend("emailConfirmed", { ...confirmed, message: "The email addresses entered do not match. Please correct and try again." });

import TextInput from "@/components/reusable/Fields/TextInput";
import { LOGIN_URL, getLocalOrgDetail, Variant } from "@/helpers/helper";
import PasswordInput from "@/components/reusable/Fields/PasswordInput";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import { BASE_URL } from "@/service_urls";
import _ from "lodash"

export default {
  components: {
    TextInput,
    PasswordInput,
    QuickRegistration,
    Modal,
    RadioButton,
  },
  props: {
    customer: {
      type: Number,
      default: null,
    },
    isCandidateSupplier: {
      type: Boolean,
      default: false,
    },
    organisationID: {
      type: Number,
    },
    locationID: {
      type: Number,
    },
    profilePhoto: {},
    isSubmitted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Variant,
      isMobile: false,
      existingCandidate: {},
      displayPage: this.isCandidateSupplier ? 4 : 1,
      isLoading: false,
      emailConfirmModal: {
        modalColor: "primary",
        modalTitle: "NOTIFICATION",
        modalContent: "Please verify that the provided email ID is correct.",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
        showCloseButton: false,
      },
      isEmailDisabled: this.campaignFieldsData ? true : false,
      userData: {},
      registeredOrg: [],
      candidate:{},
      isAlreadyRegistered: false,
      userInfo: {},
    };
  },
  computed: {
    ...mapGetters([
      "getOrgDetail",
      "getRegisteredParent",
      "getOrgVariant",
      "campaignFieldsData",
      "getCampaignById",
      "fromCampaignPage",
      "getRegistrationStep",
      "registerCandidateData",
      "getCampaignCandidateDetails",
    ]),

    getOrgName() {
      return this.getOrgDetail?.name;
    },
    hospitalName() {
      return getLocalOrgDetail()?.name ? getLocalOrgDetail()?.name : "";
    },
    isParent() {
      return getLocalOrgDetail()?.is_parent || false;
    },
    getScreenSize() {
      return {
        width: screen.availWidth,
        height: screen.availHeight,
      };
    },
    getBackgroundImage() {
      return `${BASE_URL}${this.getCampaignById?.uuid_background_image_link_to_doc?.replace(
        "/api/v1",
        ""
      )}?file=background_image&${new Date().getTime()}`;
    },
    isTFW() {
      return this.$route.params.org_name === "tfw";
    },
    registeredMasterOrg() {
      return this.registeredOrg?.filter((v) => v?.is_master) || [];
    },
    registeredOrgOpt() {
      const static_opt = {
        code: 0,
        label: `I want to create a new profile in ${this.getPathAcronym.toUpperCase()}`,
      };
      return [
        ...(this.registeredMasterOrg?.map((v) => ({
          code: v?.customer_uid,
          label: `Share this Profile : ${v?.candidate_name} - ${v?.candidate_type} - ${v?.customer_name}`,
          rawData: v,
        })) || []),
        static_opt,
      ];
    },
    selectedCustomerOrg() {
      return this.userData?.customer_id?.label || null;
    },
    getCustomerID() {
      return getLocalOrgDetail()?.customer_uid || null;
    },
    getOrgID() {
      return getLocalOrgDetail()?.organisation_id || null;
    },
    getLocationID() {
      return getLocalOrgDetail()?.location_id || null;
    },
    getRegisteredMasterCustomerName() {
      return this.registeredMasterOrg[0].customer_name || "";
    },
    routeToCandidateJobList() {
      this.$router.push("/jobs-list");
    },
    getPathAcronym() {
      let { path_acronym } = getLocalOrgDetail();
      return path_acronym;
    },
    getRegisteredMasterCustomerID() {
      return this.registeredMasterOrg[0].customer_uid || "";
    },
    getRegisteredMasterCandidateName() {
      return this.registeredMasterOrg[0].candidate_name || "";
    },
    showProfileContent() {
      return this.registeredMasterOrg[0].customer_type_id || ""
    },
    getOrgNameOrAcronym() {
      return  this.getOrgName ? this.getOrgName : this.getPathAcronym.toUpperCase()
    }
  },
  watch: {
    isCandidateSupplier() {
      this.displayPage = 4;
    },
  },
  async mounted() {
    this.scrollTop();
    let isMobile = window.matchMedia(
      "only screen and (max-width: 400px)"
    ).matches;
    this.isMobile = isMobile;
    let { check_email } = this.$route.query;

    if (check_email) {

      this.handleExistingCandidateInput("email", check_email),
      this.handleExistingCandidateInput("confirm_email", check_email)

      this.$nextTick(() => {
        this.checkCandidateExists();
      });
    } else if (this.getCampaignCandidateDetails && this.getCampaignCandidateDetails?.email) {
      const email = this.getCampaignCandidateDetails?.email;
      this.existingCandidate = {
        email,
        confirm_email: email,
      }
      this.checkCandidateExists();
    }
    this.registeredOrg = this.registerCandidateData;
    if(this.registeredOrg && this.registeredOrg.length) {
      this.existingCandidate.email = this.registeredOrg[0]?.email
      this.displayPage = this.getRegistrationStep
    }
  },
  methods: {
    ...mapActions([
      "scrollTop",
      "showToast",
      "getRegisteredHospitalsByEmail",
      "registerCommonCandidate",
      "resetCandidate",
      "clearCampaignFieldsData",
      "getCampaignsByEmail",
      "getCampaignsDataByCampaignUserID",
      "checkCandidateInTFW",
      "login",
      "isEmailBelongsToAcronym",
      "showLoader",
      "hideLoader",
      "checkCandidateExistInCampaign",
    ]),
    goToLogin() {
      if (this.getOrgVariant == Variant.campaign) {
        this.resetCandidate();
        let { path_acronym, customer_uid, organisation_id, location_id } =
          getLocalOrgDetail();

        let path = `/${path_acronym}/register/${customer_uid}`;
        if (organisation_id)
          path += `/organisation/${organisation_id}/location/${location_id}`;

        this.$router.push({ path: path });
        this.displayPage = 1;
        this.resetForm();
      } else {
        this.$router.push({ path: LOGIN_URL() });
      }
    },

    resetForm() {
      let email = this.existingCandidate["email"];
      this.existingCandidate = { email };
    },
    handleExistingCandidateInput(name, value) {
      let val = value;
      if (name === "email" || name ==='confirm_email') {
        val = value?.toLowerCase().trim();
      }
      Vue.set(this.existingCandidate, name, val);
    },
    async onExistingCandidate() {
      this.$refs.existingCandidatePassword.validate();
      const isPasswordEntered = this.userData?.password;

      if (isPasswordEntered) {
        try {
          let payload = {
            email: this.existingCandidate?.email,
            customer_uid: this.customer,
            organisation_id: this.organisationID ? this.organisationID : null,
            location_id: this.locationID ? this.locationID : null,
            password: isPasswordEntered,
          };

          await this.registerCommonCandidate(payload);

          const data = {
            username: this.existingCandidate?.email,
            password: isPasswordEntered,
          };

          await this.login(data);
          
        } catch (error) {
          console.error("An error occurred:", error);
        }
      }
    },
    checkCandidateExists() {
      this.isLoading = true;
      if (!this.existingCandidate["email"]) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Enter valid email!",
        });
        this.isLoading = false;
        return false;
      }
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(this.existingCandidate["email"]).toLowerCase())) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Enter valid email!",
        });
        this.isLoading = false;
        return false;
      }
      if (
        this.existingCandidate["email"] ===
        this.existingCandidate["confirm_email"]
      ) {
        const payload = {
          email: this.existingCandidate["email"],
          customer_uid: this.customer,
          organisation_uid: this.organisationID || null,
          check_registered: true
        };
        this.getRegisteredHospitalsByEmail(payload).then((res) => {
          this.isLoading = false;
          const errMsg =  res?.response?.data?.detail?.message ||  res?.response?.data?.detail;
          if (res?.response?.status === 409) {
            let message = errMsg;
            this.showToast({
              class: "bg-danger text-white",
              message,
            });
          } else if (
            res?.response?.status === 400 &&
            errMsg?.includes("Already registered")
          ) {
            this.userInfo = {
              candidate_name: res?.response?.data?.detail?.candidate_name,
            };
            this.isAlreadyRegistered = true;
            this.displayPage = 6;
          } else if (!res?.data) {
            // check if the candidate registered in campaign
            const payload = {
              email: this.existingCandidate["email"],
            }
            this.checkCandidateExistInCampaign(payload).then((res) => {
              this.candidate = res
              this.displayPage = 4;
            })
          } else {
            this.registeredOrg = res?.data;
            this.displayPage = 5;
          }
        });
      }
      else {
        // this.showToast({
        //   class: "bg-danger text-white",
        //   message: "The email addresses entered do not match. Please correct and try again.",
        // });
        this.isLoading = false;
        return false;
      }
    },
    checkCandidateRegisteredInCampaign(payload) {
      /**
       * check if the candidate registered in campaign
       */
      if (!payload.organisation_uid) delete payload.organisation_uid;
      this.getCampaignsByEmail(payload).then((res) => {
        // fetch more details
        if (res) {
          this.getCampaignsDataByCampaignUserID({
            campaign_user_id: res?.campaign_user_id,
            campaign_id: res?.campaign_id,
          }).then(() => {
            this.displayPage = 4;
          });
        } else {
          this.displayPage = 4;
        }
      });
    },
    handleChangeRadio(name, value) {
      Vue.set(this.userData, name, value);
    },
    handleInput(name, value) {
      Vue.set(this.userData, name, value);
    },
    checkRadioField(field) {
      if (this.userData[field] != undefined && this.userData) {
        if (field === "customer_id" && this.userData[field]?.code === 0) {
          this.existingCandidate.email = "";
          this.displayPage = 1;
          return;
        }
        this.displayPage = 6;
        return;
      }
      this.showToast({
        class: "bg-danger text-white",
        message: "Please choose your base customer!",
      });
    },
    getMasterCustomer(customer_id, display_page) {
      this.userData.customer_id = customer_id;
      this.checkRadioField("customer_id", display_page);
    },
    redirectToForgot() {
      this.$router.push({
        path: `${
          this.isAcronymBasedLogin ? `${LOGIN_URL()}/forgot` : "/forgot"
        }`,
      });
    },
    onLoginCandidate() {
      const password = this.userData?.password;
      if(!password){
        this.showToast({
          class: "bg-danger text-white",
          message: "Enter valid password!",
        });
        return false;
      }
      const data = {
        username: this.existingCandidate?.email,
        password: this.userData?.password,
      };
      this.login(data);
    },
    goBack() {
      this.existingCandidate = {};
      this.displayPage = 1;
    },
  },
  beforeDestroy() {
    this.$store.commit('CLEAR_CANDIDATE_DATA');
  },
  filters: {
    toTitleCase(data) {
      return data ? _.startCase(data) : data;
    }
  }
};
</script>

<style lang="scss" scoped>
.hint {
  opacity: 0.7;
}
.img-width {
  width: 100%;
}
@media all and (max-width: 375px) {
  .text-center > .btn {
    width: 145px;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
.action-button {
  width: 14px;
  height: 14px;
  border: 0;
  background: transparent;
  padding: 0;
  outline: none;
}
.registered-whatsapp {
  padding-top: 5px;
}
@media all and (max-width: 480px) {
  .registered-whatsapp {
    padding-top: 0%;
  }
}
.center-align {
  display: flex;
  justify-content: center;
  align-items: center;
}
#hospital-list {
  padding-left: 0.5rem;
}
.cursor-action {
  cursor: pointer;
}
.cursor-action:hover {
  text-decoration: underline;
}
.fs-12 {
  font-size: 12px;
}
.campaign-register {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.campaign-register::-webkit-scrollbar {
  width: 0; /* hide scrollbar in Chrome, Safari, and Opera */
}
.campaign-image {
  margin-top: -22px;
  margin-right: -30px;
  margin-left: -30px;
}
@media (max-width: 1084px) {
  #bg-img {
    display: none;
  }
}
.istfw {
  padding-top: 22px;
}
.registration{
  background-color: #f9f8fd;
}
@media (min-width: 1024px) {
    .custom-container {
      width: 60%;
    }
  }
</style>
